.container {
  padding-block: 10rem;
  overflow: hidden;
  width: 100%;
  padding-inline: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    padding-inline: 1rem;
  }
}

.introBox {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

@media only screen and (max-width: 50em) {
  .introText {
    font-size: 9.5rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .introText {
    font-size: 6.3rem;
  }
}
@media only screen and (max-width: 23.75em) {
  .introText {
    font-size: 5.3rem;
  }
}

.card {
  display: flex;
  justify-content: center;
  margin-block: 8rem;
}
@media only screen and (max-width: 37.5em) {
  .card {
    margin-block: 5rem;
  }
}

.btn {
  margin-block: 20rem 8rem;
}
@media only screen and (max-width: 37.5em) {
  .btn {
    margin-block: 5rem 15rem;
  }
}/*# sourceMappingURL=Contact.module.css.map */