@use '../abstracts/breakpoints';

// Margin
$margin-mg: 20rem;
$margin-kg: 20rem;
$margin-hg: 15rem;
$margin-xl: 10rem;
$margin-lg: 8rem;
$margin-md: 5rem;
$margin-sm: 1.5rem;
$margin-sm-neg: -1.5rem;
$margin-md-neg: -5rem;
$margin-lg-neg: -8rem;
$margin-xl-neg: -10rem;
$margin-hg-neg: -15rem;

// Padding
$padding-xl: 10rem;
$padding-lg: 7rem;
$padding-md: 5rem;
$padding-sm: 3rem;
$padding-mn: 1rem;
