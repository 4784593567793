@use './../abstracts/variables';

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  80% {
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}

@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

//buttons
@keyframes buttonCta {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }

  70% {
    box-shadow: none;
  }

  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }

  100% {
    box-shadow: variables.$shadow-nuemorphic-bump-1;
    color: var(--clr-neutral-900);
  }
}

@keyframes buttonGhost {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }

  70% {
    box-shadow: none;
  }

  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }

  100% {
    box-shadow: variables.$shadow-nuemorphic-pressed-1;
    color: var(--clr-ash-500);
  }
}
