@use './../../styles/abstracts/variables';
@use './../../styles/abstracts/breakpoints';
@use './../../styles/utilities/flow';

//parent
.container {
  position: relative;
  height: 30rem;
}

//children
.cardBox {
  perspective: 800px;
}

//children of cardbox
.card {
  //styling
  background-color: var(--clr-primary-300);
  box-shadow: variables.$shadow-nuemorphic-bump-1;
  transform-style: preserve-3d;
  transition: 1.2s;
  animation: moveInBottom 1s backwards ease-out 1s;

  //sizing
  width: clamp(30rem, 60vw, 65rem);
  height: clamp(28rem, 60vw, 40rem);
}

//children of card
.side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotate(0deg) translateZ(1px);
}

//hover management
@media (hover: hover) {
  .cardBox:hover .card,
  .back {
    transform: rotateY(-180deg) translateZ(1px);
  }
}

@media (hover: none) {
  .back {
    transform: rotateY(-180deg) translateZ(1px);
  }

  .card {
    animation: moveInBottom 1s backwards ease-out 1s,
      flipCard 3s forwards ease-out 2s;
  }
}

//sides
.front {
  line-height: 390px;
  text-align: center;

  @include breakpoints.respond(phone-reg) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  //styling
  outline: 1px solid var(--clr-neutral-100);
  box-shadow: variables.$shadow-nuemorphic-pressed-1;

  //typography
  text-transform: uppercase;
  font-size: 2rem;
  font-family: var(--ff-logo);
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 6px;

  //spacing
  display: inline-block;
  padding: 1.2rem 1.8rem;

  @include breakpoints.respond(phone-reg) {
    padding: 0.8rem 1.2rem;
    font-size: 1.5rem;
  }
}

/* Back styling */
.back {
  background: var(--clr-primary-300);
  padding: 30px;
  @include breakpoints.respond(phone-reg) {
    padding: 20px;
  }
}
.name {
  font-weight: 500;
}

.job {
  color: var(--clr-ash-500);
}

.paragraph {
  margin-block: 1rem;
}

.info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: var(--clr-neutral-900);

  @include breakpoints.respond(phone-reg) {
    bottom: 10px;
    left: 20px;
  }
}

.property {
  color: var(--clr-shadow-300);
  margin-right: flow.$margin-sm;
}

//animation
@keyframes flipCard {
  0% {
    transform: rotate(0deg) translateZ(1px);
  }

  50% {
    transform: rotate(0deg) translateZ(1px);
  }

  100% {
    transform: rotateY(-180deg) translateZ(1px);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  80% {
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
