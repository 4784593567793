//Shadows
$shadow-black-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-black-light: 0 1rem 2rem rgba(0, 0, 0, 0.2);
$shadow-white-dark: 0 2rem 6rem rgba(255, 255, 255, 0.3);
$shadow-white-light: 0 1rem 2rem rgba(255, 255, 255, 0.2);

$shadow-nuemorphic-bump-1: -5px -5px 20px #fff, 5px 5px 20px #babecc;
$shadow-nuemorphic-bump-2: -2px -2px 5px #fff, 2px 2px 5px #babecc;

$shadow-nuemorphic-pressed-1: inset 1px 1px 2px #babecc,
  inset -1px -1px 2px #fff;
$shadow-nuemorphic-pressed-2: inset 2px 2px 5px #babecc,
  inset -5px -5px 10px #fff;
$shadow-nuemorphic-pressed-3: inset 4px 4px 13px #babecc,
  inset -13px -13px 26px #fff;

$text-shadow: 1px 1px 0 #fff;
