@use './../../styles/abstracts/variables';
@use './../../styles/abstracts/breakpoints';

.container {
  //layout
  display: block;
  display: flex;
  justify-content: center;

  //sizing
  width: min-content;
  padding: 2rem;

  //styling
  box-shadow: variables.$shadow-nuemorphic-bump-1;
  border-radius: 15px;

  @include breakpoints.respond(phone-mini) {
    padding: 1rem;
  }
}

.video {
  width: clamp(28rem, 80vw, 80rem);

  @include breakpoints.respond(phone-reg) {
    width: clamp(28rem, 85vw, 80rem);
  }
}
