.container {
  padding-block: 10rem;
  overflow: hidden;
  width: 100%;
}

.introBox {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding-inline: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .introBox {
    padding-inline: 1rem;
  }
}

@media only screen and (max-width: 54.375em) {
  .introText {
    font-size: 8.5rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .introText {
    font-size: 5.6rem;
  }
}
@media only screen and (max-width: 23.75em) {
  .introText {
    font-size: 4.6rem;
  }
}/*# sourceMappingURL=Practices.module.css.map */