@use '../../styles/abstracts/variables';

@mixin btn-regular($type) {
  & {
    //sizing
    padding: 1.5rem 3rem;
    width: max-content;

    //typography
    text-decoration: none;
    font-family: inherit;
    font-size: var(--fs-label);
    text-shadow: variables.$text-shadow;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    //styling
    border-radius: 15px;
    border: none;
    background-color: transparent;
    transition: all 0.2s;

    //default
    appearance: none;
    cursor: pointer;

    //layout
    display: inline-block;
    position: relative;
  }

  &:focus {
    outline: none;
  }

  @if $type == primary {
    color: var(--clr-neutral-900);
    box-shadow: variables.$shadow-nuemorphic-bump-1;

    &:hover,
    &:focus {
      box-shadow: variables.$shadow-nuemorphic-bump-2;
    }

    &:active {
      box-shadow: variables.$shadow-nuemorphic-pressed-1;
    }
  }

  @if $type == ghost {
    color: var(--clr-ash-500);
    box-shadow: variables.$shadow-nuemorphic-pressed-1;

    &:hover,
    &:focus {
      box-shadow: variables.$shadow-nuemorphic-pressed-2;
    }

    &:active {
      box-shadow: variables.$shadow-nuemorphic-pressed-3;
    }
  }

  @content;
}

.btnCTA {
  @include btn-regular(primary);
}

.btnGhost {
  @include btn-regular(ghost);
}
