@use '../styles/abstracts/breakpoints';
@use '../styles/abstracts/variables';

// To be visible only at 901px up
@media only screen and (min-width: 56.26em) {
  .navigation {
    // layout
    z-index: 100;

    //animation
    animation: fade-in 1.5s backwards ease-in;

    &__tab {
      width: 100vw;
      height: 6rem;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1.5rem;
    }

    &__list {
      display: flex;
      align-items: center;
      list-style: none;
    }

    &__item {
      margin-left: 4rem;
    }

    &__link {
      //typography
      font-size: 1.7rem;
      font-weight: 400;
      color: var(--clr-shadow-500);
      text-decoration: none;

      //others
      display: block;
      padding: 0.5rem 1.5rem;
      transition: all 0.3s;

      &:hover,
      &:focus {
        color: var(--clr-ash-500);
      }

      &:focus {
        outline: none;
      }

      &:active {
        color: var(--clr-ash-500);
      }
    }
  }
}

//Burger
// To hide in Tab-Land
.burger {
  // children-1
  &__checkbox {
    display: none;
  }

  &__svg {
    display: none;
  }

  &__logoOutside {
    display: none;
  }
}

// To pop up in Tab-port
@include breakpoints.respond(tab-port) {
  .burger {
    // position: fixed;
    z-index: 100;

    //animation
    animation: fade-in 1.5s backwards ease-in;

    //children-2
    &__button {
      //position
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      z-index: 200;

      //styling
      cursor: pointer;

      //TEST
      height: 7.5rem;
      width: 100vw;

      //layout
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoints.respond(phone-reg) {
        height: 7rem;
      }
    }

    //burger svg
    &__svg {
      display: block;
      height: 7rem;
      width: 7rem;
      position: relative;
      z-index: 1000;
      top: 0;

      @include breakpoints.respond(phone-reg) {
        height: 6rem;
        width: 6rem;
      }
    }

    //logo outside
    &__logoOutside {
      display: block;
    }

    //lines
    &__line {
      fill: none;
      stroke: var(--clr-shadow-300);
      stroke-width: 5; //var(--svg-size-width)
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &__line1 {
      stroke-dasharray: 60 207;
      stroke-width: 5;
    }
    &__line2 {
      stroke-dasharray: 60 60;
      stroke-width: 5;
    }
    &__line3 {
      stroke-dasharray: 60 207;
      stroke-width: 5;
    }
    &__checkbox:checked + &__button &__line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 5;
    }
    &__checkbox:checked + &__button &__line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 5;
    }
    &__checkbox:checked + &__button &__line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 5;
    }

    //contents
    &__content {
      //position
      position: absolute;
      top: 0rem;
      left: 2rem;
      display: block;
      z-index: 100;
      margin: 1rem -1rem;

      //sizing
      height: 0;
      width: 30rem;

      //styling
      transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
      background-color: var(--clr-primary-300);
      box-shadow: variables.$shadow-nuemorphic-pressed-1;
      border-radius: 15px;
      opacity: 0;
      visibility: hidden;

      //alignment
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      @include breakpoints.respond(phone-mini) {
        width: 25rem;
      }
    }

    &__logo-box {
      display: none;
    }

    &__list {
      //sizing
      flex-basis: 70%;

      //typography
      list-style: none;
      z-index: 500;

      //alignment
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__link {
      //typography
      font-size: 2rem;
      font-weight: 300;
      text-decoration: none;
      text-transform: uppercase;
      color: var(--clr-shadow-500);

      //others
      display: inline-block;
      transition: all 0.4s;
      padding: 1rem 2rem;
      transition: all 0.3s ease-out;

      &:hover,
      &:focus {
        color: var(--clr-ash-500);
      }

      &:focus {
        outline: none;
      }

      &:active {
        color: var(--clr-ash-500);
      }
    }

    //Functionality
    &__checkbox:checked + &__button &__content {
      opacity: 1;
      visibility: visible;
      height: 50rem;

      @include breakpoints.respond(phone-reg) {
        height: 40rem;
      }
    }
  }
}

// Switcher
.activeNav {
  box-shadow: variables.$shadow-nuemorphic-pressed-1;
  color: var(--clr-ash-500);
}

// Animation
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
