@use './../abstracts/variables';

//navigation
.navSticky {
  position: fixed;
  background-color: var(--clr-primary-300);
  box-shadow: variables.$shadow-nuemorphic-bump-1;
}

.navAbsolute {
  position: absolute;
  background-color: transparent;
}

//animation
.hidden {
  opacity: 0;
}

.reveal {
  animation: reveal 1s backwards ease-out 2s;
}

.moveInRight {
  animation: moveInRight 1.5s backwards ease-out 0.5s;
}

.moveInLeft {
  animation: moveInLeft 1.5s backwards ease-out 0.5s;
}

.moveInBottom {
  animation: moveInBottom 1s backwards ease-out;
}

.moveInBottomIntro {
  animation: moveInBottom 1s backwards ease-out 1s;
}

.textClip {
  animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}

.outerLeft {
  animation: outer-left 1s 1s ease backwards;
}

//button
.buttonCta {
  animation: buttonCta 2s backwards ease-out;
}

.buttonGhost {
  animation: buttonGhost 2s backwards ease-out;
}
