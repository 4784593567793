@use './../styles/utilities/flow';
@use './../styles/abstracts/breakpoints';

//parent
.container {
  //layout
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  //sizing
  height: 100vh;
  overflow: hidden;
}

//children-1
.contentBox {
  width: min-content;
}

.text1 {
  font-weight: 300;
  font-size: var(--fs-heading);
  display: block;
}

.text3,
.text4 {
  animation: colorChange 3s backwards ease-out;
}

.text4 {
  @include breakpoints.respond(phone-mini) {
    font-size: 7.5rem;
  }
}

//children-2
.button {
  margin-top: flow.$margin-sm;
}

//children-3
.floaterBox {
  position: absolute;
}

//animation
@keyframes colorChange {
  0% {
    -webkit-text-stroke: 1px var(--clr-shadow-100);
    color: transparent;
  }

  30% {
    color: var(--clr-shadow-100);
  }

  100% {
    color: var(--clr-neutral-900);
  }
}
