.container {
  padding-inline: 5rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    padding-inline: 1rem;
  }
}

.text {
  margin-bottom: 10rem;
  width: 45%;
}/*# sourceMappingURL=Inviter.module.css.map */