@use './../../styles/utilities/flow';
@use './../../styles/abstracts/breakpoints';

.container {
  //layout
  display: grid;
  grid-template-areas:
    'car1 txt'
    '. car2'
    '. btn';
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  align-items: center;
  gap: 5rem;

  //spacing
  padding-inline: flow.$padding-md;
  margin-block: flow.$margin-kg;

  @include breakpoints.respond(tab-port) {
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      '. txt .'
      '. car1 .'
      '. car2 .'
      '. btn .';
    gap: 10rem;
    margin-block: flow.$margin-hg;
  }

  @include breakpoints.respond(phone-reg) {
    margin-block: flow.$margin-lg;
  }
}

.heading {
  width: 60%;
  justify-self: center;
  grid-area: txt;
  color: var(--clr-ash-500);
  font-weight: 300;

  @include breakpoints.respond(phone-reg) {
    width: 70%;
  }
}

.carousel1 {
  grid-area: car1;
}

.carousel2 {
  grid-area: car2;
}

.btn {
  grid-area: btn;
}
