$breakpoints: (
  phone-mini: 23.75em,
  // 380px
  phone-reg: 37.5em,
  // 600px
  tab-port: 56.25em,
  // 900px
  tab-land: 75em,
  // 1200px
  desktop-big: 112.5em,
  // 1800px
);

@mixin respond($key) {
  $size: map-get($breakpoints, $key);

  @media only screen and (max-width: $size) {
    @content;
  }
}
