@use './../../styles/utilities/flow';
@use './../../styles/abstracts/breakpoints';

//parent
.container {
  margin-bottom: flow.$margin-kg;
  animation: colorChange 2s forwards ease-out;
}

//children-2
.textBoxOuter {
  animation: outer-left 1s 1s ease backwards;

  @include breakpoints.respond(phone-reg) {
    animation: outer-up 1s 1s ease backwards;
  }
}

.textBoxInner {
  animation: inner-left 1s 1s ease backwards;

  @include breakpoints.respond(phone-reg) {
    animation: inner-up 1s 1s ease backwards;
  }
}

.textBoxInner1,
.textBoxInner2 {
  animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;

  @include breakpoints.respond(phone-reg) {
    animation: outer-up 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;
  }
}

.text1 {
  animation: inner-left 1s 1s ease backwards,
    text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;

  @include breakpoints.respond(phone-reg) {
    animation: inner-up 1s 1s ease backwards,
      text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
  }
}

.text2 {
  animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}

.questionmark {
  display: inline-block;
  transition: all 1s;
  transform: rotate(95deg) translateX(1rem);
  animation: questionmark 1.3s forwards ease-out 0.5s;
}

//animation
@keyframes questionmark {
  0% {
    transform: rotate(95deg) translateX(1rem);
  }

  50% {
    transform: rotate(85deg) translateX(1rem);
  }

  70% {
    transform: rotate(45deg) translateX(0.5rem);
  }

  90% {
    transform: rotate(-5deg) translateX(-0.5rem);
    color: var(--clr-shadow-500);
  }

  100% {
    transform: rotate(0) translateX(0);
    color: var(--clr-neutral-900);
  }
}

@keyframes colorChange {
  0% {
    color: var(--clr-shadow-500);
  }

  80% {
    color: var(--clr-shadow-500);
  }

  100% {
    color: var(--clr-neutral-900);
  }
}

@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}

@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}

@keyframes outer-up {
  from {
    transform: translateY(200%);
  }
  to {
    transform: none;
  }
}

@keyframes inner-up {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: none;
  }
}
