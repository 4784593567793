.container {
  position: relative;
  padding-block: 10rem;
  padding-inline: 3rem;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    padding-inline: 1rem;
  }
}

.heading {
  align-self: flex-start;
  padding-inline: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .heading {
    padding-inline: 1rem;
  }
}

.brandImg {
  justify-self: center;
}

.video {
  margin-block: 8rem;
  margin-inline: auto;
}
@media only screen and (max-width: 37.5em) {
  .video {
    margin-top: 5rem;
    margin-bottom: 0;
  }
}

.contentBox {
  width: 100%;
  padding-inline: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  grid-template-areas: "head text1" ". text2" ". text3" "back btn";
}
.contentBox .contentHeading {
  grid-area: head;
  align-self: flex-start;
}
.contentBox .contentParagraph1 {
  grid-area: text1;
}
.contentBox .contentParagraph2 {
  grid-area: text2;
}
.contentBox .contentParagraph3 {
  grid-area: text3;
}
.contentBox .button {
  grid-area: btn;
  justify-self: end;
}
.contentBox .buttonBack {
  grid-area: back;
}
@media only screen and (max-width: 56.25em) {
  .contentBox .buttonBack {
    justify-self: end;
  }
}
@media only screen and (max-width: 56.25em) {
  .contentBox {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "head" "text1" "text2" "text3" "btn" "back";
    gap: 5rem;
    padding-inline: 1rem;
  }
}

.contentHeading {
  width: 30rem;
}

.contentParagraph {
  color: var(--clr-ash-500);
  margin-bottom: 8rem;
  line-height: 2;
}
@media only screen and (max-width: 56.25em) {
  .contentParagraph {
    margin-bottom: 1.5rem;
  }
}

.highlightText {
  color: var(--clr-neutral-900);
}

.button {
  margin-top: 5rem;
}
@media only screen and (max-width: 56.25em) {
  .button {
    margin-top: 1.5rem;
  }
}

.buttonBack {
  color: var(--clr-ash-500);
}/*# sourceMappingURL=IndividualWorks.module.css.map */