@use './../../styles/utilities/flow';
@use './../../styles/abstracts/breakpoints';
@use './../../styles/abstracts/variables';

.container {
  margin-block: flow.$margin-kg;
  padding-inline: flow.$padding-sm;

  @include breakpoints.respond(phone-reg) {
    margin-block: flow.$margin-hg;
    padding-inline: flow.$padding-mn;
  }
}

.video {
  margin-block: flow.$margin-hg;
  margin-inline: auto;

  @include breakpoints.respond(phone-reg) {
    margin-block: flow.$margin-lg;
  }
}

//children-3
.contentBox {
  //others
  width: 100%;
  padding-inline: flow.$padding-md;

  //layout
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  grid-template-areas:
    'head txt'
    '. btn';

  .contentHeading {
    grid-area: head;
    align-self: flex-start;
  }

  .contentParagraph {
    grid-area: txt;
  }

  .button {
    grid-area: btn;
    justify-self: end;
  }

  @include breakpoints.respond(phone-reg) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'head'
      'txt'
      'btn';
    gap: 5rem;
    padding-inline: flow.$padding-mn;
  }
}

.contentHeading {
  width: 30rem;
}

.contentBullet {
  color: var(--clr-ash-500);
  line-height: 2;
}

.bullet {
  padding-left: 1rem;
  &::marker {
    color: var(--clr-neutral-900);
    content: '✓';
  }
}

.button {
  margin-top: flow.$margin-md;
  @include breakpoints.respond(phone-reg) {
    margin-top: flow.$margin-sm;
  }
}
