.container {
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.small {
  display: inline-block;
  transform: rotate(-90deg) translateX(2rem);
  font-weight: 300;
  font-size: var(--fs-heading);
}
@media only screen and (max-width: 37.5em) {
  .small {
    transform: rotate(-90deg) translateX(1rem);
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 23.75em) {
  .small {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .large {
    font-size: 7.5rem;
  }
}/*# sourceMappingURL=VerticalAdjaText.module.css.map */