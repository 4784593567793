@use './../../styles/abstracts/variables';
.btnText {
  //styling
  background: none;
  padding: 3px;
  width: max-content;
  appearance: none;
  border: none;
  border-bottom: 1px solid var(--clr-shadow-300);
  cursor: pointer;

  //typography
  text-decoration: none;
  font-family: inherit;
  font-size: var(--fs-label);
  font-weight: 500;
  letter-spacing: 0.2rem;

  //misc
  display: inline-block;
  transition: all 0.2s;

  &:hover,
  &:focus {
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}
