.btnText {
  background: none;
  padding: 3px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  border-bottom: 1px solid var(--clr-shadow-300);
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  font-size: var(--fs-label);
  font-weight: 500;
  letter-spacing: 0.2rem;
  display: inline-block;
  transition: all 0.2s;
}
.btnText:hover, .btnText:focus {
  transform: translateY(-2px);
}
.btnText:focus {
  outline: none;
}
.btnText:active {
  transform: translateY(2px);
}/*# sourceMappingURL=ButtonText.module.css.map */