/*
$color-primary: #EBECF0
$color-ash: #eaeaea
$color-shadow #BABECC
*/

$colors: (
  'primary': (
    100: hsl(228, 14.3%, 100%),
    200: hsl(228, 14.3%, 96.1%),
    300: hsl(228, 14.3%, 93.1%),
    400: hsl(228, 14.3%, 90.1%),
    500: hsl(228, 14.3%, 85.1%),
  ),
  'shadow': (
    100: hsl(226.7, 15%, 86.5%),
    200: hsl(226.7, 15%, 81.5%),
    300: hsl(226.7, 15%, 76.5%),
    400: hsl(226.7, 15%, 71.5%),
    500: hsl(226.7, 15%, 66.5%),
  ),
  'ash': (
    100: hsl(0, 0%, 91.8%),
    200: hsl(40, 4.6%, 87.3%),
    300: hsl(0, 0%, 74.1%),
    400: hsl(15, 1.9%, 58.8%),
    500: hsl(0, 0%, 42.4%),
  ),
  'neutral': (
    100: hsl(0, 0%, 100%),
    200: hsl(0, 0%, 87%),
    300: hsl(0, 0%, 80%),
    400: hsl(0, 0%, 60%),
    500: hsl(0, 0%, 50%),
    600: hsl(0, 0%, 40%),
    700: hsl(0, 0%, 30%),
    800: hsl(0, 0%, 15%),
    900: hsl(0, 0%, 0%),
  ),
  'primary-opacity': (
    100: hsl(228, 14.3%, 93.1%, 0.1),
    200: hsl(228, 14.3%, 93.1%, 0.2),
    300: hsl(228, 14.3%, 93.1%, 0.4),
    400: hsl(228, 14.3%, 93.1%, 0.6),
    500: hsl(228, 14.3%, 93.1%, 0.8),
  ),
  'ash-opacity': (
    100: hsl(0, 0%, 42.4%, 0.1),
    200: hsl(0, 0%, 42.4%, 0.2),
    300: hsl(0, 0%, 42.4%, 0.4),
    400: hsl(0, 0%, 42.4%, 0.6),
    500: hsl(0, 0%, 42.4%, 0.8),
  ),
  'shadow-opacity': (
    100: hsl(226.7, 15%, 76.5%, 0.1),
    200: hsl(226.7, 15%, 76.5%, 0.2),
    300: hsl(226.7, 15%, 76.5%, 0.4),
    400: hsl(226.7, 15%, 76.5%, 0.6),
    500: hsl(226.7, 15%, 76.5%, 0.8),
  ),
  'white-opacity': (
    100: hsl(0, 0%, 100%, 0.2),
    200: hsl(0, 0%, 100%, 0.4),
    300: hsl(0, 0%, 100%, 0.6),
    400: hsl(0, 0%, 100%, 0.8),
    500: hsl(0, 0%, 100%),
  ),
  'black-opacity': (
    100: hsl(0, 0%, 0%, 0.2),
    200: hsl(0, 0%, 0%, 0.4),
    300: hsl(0, 0%, 0%, 0.6),
    400: hsl(0, 0%, 0%, 0.8),
    500: hsl(0, 0%, 0%),
  ),
);

:root {
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --clr-#{$color}-#{$shade}: #{$value};
    }
  }
}
