@use './../../styles/utilities/flow';
@use './../../styles/abstracts/breakpoints';

.container {
  padding-inline: flow.$padding-md;

  @include breakpoints.respond(phone-reg) {
    padding-inline: flow.$padding-mn;
  }
}

.text {
  margin-bottom: flow.$margin-xl;
  width: 45%;
}
