.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}/*# sourceMappingURL=ButtonBox.module.css.map */