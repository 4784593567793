$base: var(--clr-primary-300);
$lighten: var(--clr-primary-200);
$darken: var(--clr-primary-400);
$white: var(--clr-neutral-100);
$shadow: 6px 6px 12px $darken, -6px -6px 12px $lighten;
$inset: inset 6px 6px 12px $darken, inset -6px -6px 12px $lighten;

.container {
  //position
  position: absolute;
  top: -20rem;
  left: 30%;

  //sizing
  width: 100vw;
  height: 100vh;

  //layout
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  //position
  z-index: 10;
  position: absolute;

  //sizing
  width: 40px;
  height: 40px;

  //styling
  background-color: $base;
  color: $white;
  border: none;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  animation: shadowFadeOut 3000ms ease-out forwards infinite,
    shadowFadeIn 3000ms 300ms ease-in forwards infinite;

  &::before {
    //position
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 1;
    position: absolute;

    //sizing
    width: 20px;
    height: 20px;

    //styling
    border-radius: 50%;
    box-shadow: 20px 20px 60px $darken, -20px -20px 60px $lighten;
    opacity: 0;
    animation: fadeIn 400ms ease-out forwards infinite,
      outside_grow 5s ease-out infinite, fadeOut 3s 2s forwards infinite;
  }

  &::after {
    //position
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    box-shadow: inset 20px 20px 60px $darken, inset -20px -20px 60px $lighten;
    animation: inside_grow 5s ease-out infinite;
  }
}

/** Animation */
@keyframes outside_grow {
  from {
    width: 20px;
    height: 20px;
  }
  to {
    width: 900px;
    height: 900px;
  }
}

@keyframes inside_grow {
  from {
    width: 0px;
    height: 0px;
  }
  to {
    width: 880px;
    height: 880px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes shadowFadeIn {
  0% {
    box-shadow: $inset;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: $shadow;
  }
}

@keyframes shadowFadeOut {
  0% {
    box-shadow: $shadow;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: $inset;
  }
}
