.btnCTA {
  color: var(--clr-neutral-900);
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
}
.btnCTA {
  padding: 1.5rem 3rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-decoration: none;
  font-family: inherit;
  font-size: var(--fs-label);
  text-shadow: 1px 1px 0 #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  border-radius: 15px;
  border: none;
  background-color: transparent;
  transition: all 0.2s;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.btnCTA:focus {
  outline: none;
}
.btnCTA:hover, .btnCTA:focus {
  box-shadow: -2px -2px 5px #fff, 2px 2px 5px #babecc;
}
.btnCTA:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.btnGhost {
  color: var(--clr-ash-500);
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}
.btnGhost {
  padding: 1.5rem 3rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-decoration: none;
  font-family: inherit;
  font-size: var(--fs-label);
  text-shadow: 1px 1px 0 #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  border-radius: 15px;
  border: none;
  background-color: transparent;
  transition: all 0.2s;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.btnGhost:focus {
  outline: none;
}
.btnGhost:hover, .btnGhost:focus {
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #fff;
}
.btnGhost:active {
  box-shadow: inset 4px 4px 13px #babecc, inset -13px -13px 26px #fff;
}/*# sourceMappingURL=ButtonReg.module.css.map */