.container {
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fs-label);
  color: var(--clr-shadow-500);
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.link {
  padding: 1.5rem;
  display: block;
  transition: all 0.2s;
}
.link:hover, .link:focus {
  transform: translateY(-3px);
}
.link:focus {
  outline: none;
}
.link:active {
  transform: translateY(3px);
}/*# sourceMappingURL=Footer.module.css.map */