.container {
  display: flex;
  width: 100%;
}
@media only screen and (max-width: 56.25em) {
  .container {
    flex-direction: column;
    gap: 3rem;
  }
}

.number {
  font-weight: 200;
}

.title {
  width: 50%;
  margin-left: 5rem;
  justify-self: flex-end;
}
@media only screen and (max-width: 75em) {
  .title {
    font-size: 10rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .title {
    justify-self: center;
    margin-left: 0;
    font-size: 7.5rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .title {
    font-size: 4.9rem;
  }
}
@media only screen and (max-width: 23.75em) {
  .title {
    font-size: 4rem;
  }
}/*# sourceMappingURL=NumberedItem.module.css.map */