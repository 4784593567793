.container {
  display: grid;
  grid-template-areas: "car1 txt" ". car2" ". btn";
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  align-items: center;
  gap: 5rem;
  padding-inline: 5rem;
  margin-block: 20rem;
}
@media only screen and (max-width: 56.25em) {
  .container {
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: ". txt ." ". car1 ." ". car2 ." ". btn .";
    gap: 10rem;
    margin-block: 15rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .container {
    margin-block: 8rem;
  }
}

.heading {
  width: 60%;
  justify-self: center;
  grid-area: txt;
  color: var(--clr-ash-500);
  font-weight: 300;
}
@media only screen and (max-width: 37.5em) {
  .heading {
    width: 70%;
  }
}

.carousel1 {
  grid-area: car1;
}

.carousel2 {
  grid-area: car2;
}

.btn {
  grid-area: btn;
}/*# sourceMappingURL=CarouselShowcase.module.css.map */