@charset "UTF-8";
.container {
  margin-block: 20rem;
  padding-inline: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    margin-block: 15rem;
    padding-inline: 1rem;
  }
}

.video {
  margin-block: 15rem;
  margin-inline: auto;
}
@media only screen and (max-width: 37.5em) {
  .video {
    margin-block: 8rem;
  }
}

.contentBox {
  width: 100%;
  padding-inline: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  grid-template-areas: "head txt" ". btn";
}
.contentBox .contentHeading {
  grid-area: head;
  align-self: flex-start;
}
.contentBox .contentParagraph {
  grid-area: txt;
}
.contentBox .button {
  grid-area: btn;
  justify-self: end;
}
@media only screen and (max-width: 37.5em) {
  .contentBox {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "head" "txt" "btn";
    gap: 5rem;
    padding-inline: 1rem;
  }
}

.contentHeading {
  width: 30rem;
}

.contentBullet {
  color: var(--clr-ash-500);
  line-height: 2;
}

.bullet {
  padding-left: 1rem;
}
.bullet::marker {
  color: var(--clr-neutral-900);
  content: "✓";
}

.button {
  margin-top: 5rem;
}
@media only screen and (max-width: 37.5em) {
  .button {
    margin-top: 1.5rem;
  }
}/*# sourceMappingURL=WorksContent.module.css.map */