.container {
  margin-block: 15rem;
  padding-inline: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    padding-inline: 1rem;
  }
}

.video {
  margin-block: 15rem;
  margin-inline: auto;
}
@media only screen and (max-width: 37.5em) {
  .video {
    margin-block: 8rem;
  }
}

.contentBox {
  width: 100%;
  padding-inline: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  grid-template-areas: "head text1" ". text2" ". btn";
}
.contentBox .contentHeading {
  grid-area: head;
  align-self: flex-start;
}
.contentBox .contentParagraph1 {
  grid-area: text1;
}
.contentBox .contentParagraph2 {
  grid-area: text2;
}
.contentBox .button {
  grid-area: btn;
  justify-self: end;
}
@media only screen and (max-width: 37.5em) {
  .contentBox {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "head" "text1" "text2" "btn";
    gap: 5rem;
    padding-inline: 1rem;
  }
}

.contentHeading {
  width: 30rem;
}

.contentParagraph {
  color: var(--clr-ash-500);
  line-height: 2;
  margin-bottom: 8rem;
}
@media only screen and (max-width: 37.5em) {
  .contentParagraph {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .contentParagraph1 {
    margin-top: 10rem;
  }
}

.highlightTextHeading {
  text-transform: uppercase;
}

.highlightText {
  color: var(--clr-neutral-900);
}

.button {
  margin-top: 5rem;
}
@media only screen and (max-width: 37.5em) {
  .button {
    margin-top: 1.5rem;
  }
}/*# sourceMappingURL=PracticesContent.module.css.map */