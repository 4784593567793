*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-weight: 400;
  line-height: 1.6;
  color: var(--clr-neutral-900);
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  background-color: var(--clr-primary-300);
}

::-moz-selection {
  background-color: var(--clr-neutral-100);
}

::selection {
  background-color: var(--clr-neutral-100);
}

.container-main {
  background-color: var(--clr-primary-300);
}

/*
$color-primary: #EBECF0
$color-ash: #eaeaea
$color-shadow #BABECC
*/
:root {
  --clr-primary-100: hsl(228deg, 14.3%, 100%);
  --clr-primary-200: hsl(228deg, 14.3%, 96.1%);
  --clr-primary-300: hsl(228deg, 14.3%, 93.1%);
  --clr-primary-400: hsl(228deg, 14.3%, 90.1%);
  --clr-primary-500: hsl(228deg, 14.3%, 85.1%);
  --clr-shadow-100: hsl(226.7deg, 15%, 86.5%);
  --clr-shadow-200: hsl(226.7deg, 15%, 81.5%);
  --clr-shadow-300: hsl(226.7deg, 15%, 76.5%);
  --clr-shadow-400: hsl(226.7deg, 15%, 71.5%);
  --clr-shadow-500: hsl(226.7deg, 15%, 66.5%);
  --clr-ash-100: hsl(0deg, 0%, 91.8%);
  --clr-ash-200: hsl(40deg, 4.6%, 87.3%);
  --clr-ash-300: hsl(0deg, 0%, 74.1%);
  --clr-ash-400: hsl(15deg, 1.9%, 58.8%);
  --clr-ash-500: hsl(0deg, 0%, 42.4%);
  --clr-neutral-100: hsl(0deg, 0%, 100%);
  --clr-neutral-200: hsl(0deg, 0%, 87%);
  --clr-neutral-300: hsl(0deg, 0%, 80%);
  --clr-neutral-400: hsl(0deg, 0%, 60%);
  --clr-neutral-500: hsl(0deg, 0%, 50%);
  --clr-neutral-600: hsl(0deg, 0%, 40%);
  --clr-neutral-700: hsl(0deg, 0%, 30%);
  --clr-neutral-800: hsl(0deg, 0%, 15%);
  --clr-neutral-900: hsl(0deg, 0%, 0%);
  --clr-primary-opacity-100: hsla(228deg, 14.3%, 93.1%, 0.1);
  --clr-primary-opacity-200: hsla(228deg, 14.3%, 93.1%, 0.2);
  --clr-primary-opacity-300: hsla(228deg, 14.3%, 93.1%, 0.4);
  --clr-primary-opacity-400: hsla(228deg, 14.3%, 93.1%, 0.6);
  --clr-primary-opacity-500: hsla(228deg, 14.3%, 93.1%, 0.8);
  --clr-ash-opacity-100: hsla(0deg, 0%, 42.4%, 0.1);
  --clr-ash-opacity-200: hsla(0deg, 0%, 42.4%, 0.2);
  --clr-ash-opacity-300: hsla(0deg, 0%, 42.4%, 0.4);
  --clr-ash-opacity-400: hsla(0deg, 0%, 42.4%, 0.6);
  --clr-ash-opacity-500: hsla(0deg, 0%, 42.4%, 0.8);
  --clr-shadow-opacity-100: hsla(226.7deg, 15%, 76.5%, 0.1);
  --clr-shadow-opacity-200: hsla(226.7deg, 15%, 76.5%, 0.2);
  --clr-shadow-opacity-300: hsla(226.7deg, 15%, 76.5%, 0.4);
  --clr-shadow-opacity-400: hsla(226.7deg, 15%, 76.5%, 0.6);
  --clr-shadow-opacity-500: hsla(226.7deg, 15%, 76.5%, 0.8);
  --clr-white-opacity-100: hsla(0deg, 0%, 100%, 0.2);
  --clr-white-opacity-200: hsla(0deg, 0%, 100%, 0.4);
  --clr-white-opacity-300: hsla(0deg, 0%, 100%, 0.6);
  --clr-white-opacity-400: hsla(0deg, 0%, 100%, 0.8);
  --clr-white-opacity-500: hsl(0deg, 0%, 100%);
  --clr-black-opacity-100: hsla(0deg, 0%, 0%, 0.2);
  --clr-black-opacity-200: hsla(0deg, 0%, 0%, 0.4);
  --clr-black-opacity-300: hsla(0deg, 0%, 0%, 0.6);
  --clr-black-opacity-400: hsla(0deg, 0%, 0%, 0.8);
  --clr-black-opacity-500: hsl(0deg, 0%, 0%);
}

/* Font Sizes  */
:root {
  /* Font Sizes  */
  --fs-display: 13rem;
  --fs-heading: 3.6rem;
  --fs-subheading: 2.4rem;
  --fs-label: 1.8rem;
  --fs-body: 1.4rem;
  --fs-caption: 1rem;
  /* Font Family */
  --ff-primary: "Poppins", sans-serif;
  --ff-logo: "Comfortaa", sans-serif;
}
@media only screen and (max-width: 37.5em) {
  :root {
    --fs-display: 8rem;
    --fs-displayAlt: 7rem;
    --fs-heading: 3rem;
    --fs-subheading: 2rem;
    --fs-label: 1.4rem;
    --fs-body: 1rem;
    --fs-caption: 0.8rem;
  }
}

.heading-display {
  font-size: var(--fs-display);
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.heading-1 {
  font-size: var(--fs-heading);
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
}

.heading-2 {
  font-size: var(--fs-subheading);
  font-weight: 500;
  letter-spacing: 0.3rem;
}

.heading-3 {
  font-size: var(--fs-label);
  letter-spacing: 0.3rem;
  font-weight: 300;
}

.caption {
  font-size: var(--fs-caption);
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@-webkit-keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@-webkit-keyframes buttonCta {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  70% {
    box-shadow: none;
  }
  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  100% {
    box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
    color: var(--clr-neutral-900);
  }
}
@keyframes buttonCta {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  70% {
    box-shadow: none;
  }
  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  100% {
    box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
    color: var(--clr-neutral-900);
  }
}
@-webkit-keyframes buttonGhost {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  70% {
    box-shadow: none;
  }
  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  100% {
    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
    color: var(--clr-ash-500);
  }
}
@keyframes buttonGhost {
  0% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  70% {
    box-shadow: none;
  }
  80% {
    box-shadow: none;
    color: var(--clr-shadow-300);
  }
  100% {
    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
    color: var(--clr-ash-500);
  }
}
.spinner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner__squares {
  width: 12em;
  height: 12em;
  position: relative;
}
.spinner__square {
  -webkit-animation: smallPopOut1 1s linear, popInOut 6s 1s linear infinite;
          animation: smallPopOut1 1s linear, popInOut 6s 1s linear infinite;
  border-radius: 0.5em;
  box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  position: absolute;
  top: 2.5em;
  left: 2.5em;
  width: 3em;
  height: 3em;
}
.spinner__square:nth-child(n+2):nth-child(-n+3) {
  left: 6.5em;
}
.spinner__square:nth-child(n+3) {
  top: 6.5em;
}
.spinner__square:nth-child(2) {
  -webkit-animation: smallPopOut2 1s linear, move2 6s 1s linear infinite;
          animation: smallPopOut2 1s linear, move2 6s 1s linear infinite;
}
.spinner__square:nth-child(3) {
  -webkit-animation: smallPopOut3 1s linear, move3 6s 1s linear infinite;
          animation: smallPopOut3 1s linear, move3 6s 1s linear infinite;
}
.spinner__square:nth-child(4) {
  -webkit-animation: smallPopOut4 1s linear, move4 6s 1s linear infinite;
          animation: smallPopOut4 1s linear, move4 6s 1s linear infinite;
}
.spinner .status {
  -webkit-animation: fadeIn 1s linear forwards;
          animation: fadeIn 1s linear forwards;
  text-align: center;
}
.spinner .status__dot {
  -webkit-animation: appear1 1s 1s steps(1, start) infinite;
          animation: appear1 1s 1s steps(1, start) infinite;
  display: inline-block;
}
.spinner .status__dot:nth-child(2) {
  -webkit-animation: appear2 1s 1s steps(1, start) infinite;
          animation: appear2 1s 1s steps(1, start) infinite;
}
.spinner .status__dot:nth-child(3) {
  -webkit-animation: appear3 1s 1s steps(1, start) infinite;
          animation: appear3 1s 1s steps(1, start) infinite;
}
.spinner__text {
  color: var(--clr-shadow-500);
}

/* Animations */
@-webkit-keyframes smallPopOut1 {
  from, 40% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  60% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  80%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@keyframes smallPopOut1 {
  from, 40% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  60% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  80%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@-webkit-keyframes smallPopOut2 {
  from, 45% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  65% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  85%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@keyframes smallPopOut2 {
  from, 45% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  65% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  85%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@-webkit-keyframes smallPopOut3 {
  from, 50% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  70% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  90%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@keyframes smallPopOut3 {
  from, 50% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  70% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  90%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@-webkit-keyframes smallPopOut4 {
  from, 55% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  75% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  95%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@keyframes smallPopOut4 {
  from, 55% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
  }
  75% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
  }
  95%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
  }
}
@-webkit-keyframes popInOut {
  from {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  4% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  8% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  12%, 16% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  20% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  24%, 25% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  29% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  33% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  37%, 41% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  45% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  49%, 50% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  54% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  58% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  62%, 66% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  70% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  74%, 75% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  79% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  83% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  87%, 91% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  95% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  99%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
}
@keyframes popInOut {
  from {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  4% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  8% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  12%, 16% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  20% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  24%, 25% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  29% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  33% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 0);
  }
  37%, 41% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  45% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  49%, 50% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  54% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  58% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(4em, 4em);
  }
  62%, 66% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  70% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  74%, 75% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  79% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  83% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 4em);
  }
  87%, 91% {
    box-shadow: 0 0 0 var(--clr-neutral-100) inset, 0 0 0 var(--clr-shadow-300) inset, 0 0 0 var(--clr-shadow-300), 0 0 0 var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  95% {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.5em 0.5em 0.5em var(--clr-shadow-300), -0.5em -0.5em 1em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
  99%, to {
    box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 0.25em 0.25em 0.5em var(--clr-shadow-300), -0.25em -0.25em 0.5em var(--clr-neutral-100);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes move2 {
  from, 8% {
    transform: translate(0, 0);
    width: 3em;
    height: 3em;
  }
  12% {
    transform: translate(-4em, 0);
    width: 7em;
    height: 3em;
  }
  16%, 83% {
    transform: translate(-4em, 0);
    width: 3em;
    height: 3em;
  }
  87% {
    transform: translate(-4em, 0);
    width: 3em;
    height: 7em;
  }
  91%, to {
    transform: translate(-4em, 4em);
    width: 3em;
    height: 3em;
  }
}
@keyframes move2 {
  from, 8% {
    transform: translate(0, 0);
    width: 3em;
    height: 3em;
  }
  12% {
    transform: translate(-4em, 0);
    width: 7em;
    height: 3em;
  }
  16%, 83% {
    transform: translate(-4em, 0);
    width: 3em;
    height: 3em;
  }
  87% {
    transform: translate(-4em, 0);
    width: 3em;
    height: 7em;
  }
  91%, to {
    transform: translate(-4em, 4em);
    width: 3em;
    height: 3em;
  }
}
@-webkit-keyframes move3 {
  from, 33% {
    transform: translate(0, 0);
    height: 3em;
  }
  37% {
    transform: translate(0, -4em);
    height: 7em;
  }
  41%, to {
    transform: translate(0, -4em);
    height: 3em;
  }
}
@keyframes move3 {
  from, 33% {
    transform: translate(0, 0);
    height: 3em;
  }
  37% {
    transform: translate(0, -4em);
    height: 7em;
  }
  41%, to {
    transform: translate(0, -4em);
    height: 3em;
  }
}
@-webkit-keyframes move4 {
  from, 58% {
    transform: translate(0, 0);
    width: 3em;
  }
  62% {
    transform: translate(0, 0);
    width: 7em;
  }
  66%, to {
    transform: translate(4em, 0);
    width: 3em;
  }
}
@keyframes move4 {
  from, 58% {
    transform: translate(0, 0);
    width: 3em;
  }
  62% {
    transform: translate(0, 0);
    width: 7em;
  }
  66%, to {
    transform: translate(4em, 0);
    width: 3em;
  }
}
@-webkit-keyframes fadeIn {
  from, 67% {
    opacity: 0;
  }
  83.3%, to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from, 67% {
    opacity: 0;
  }
  83.3%, to {
    opacity: 1;
  }
}
@-webkit-keyframes appear1 {
  from {
    visibility: hidden;
  }
  33%, to {
    visibility: visible;
  }
}
@keyframes appear1 {
  from {
    visibility: hidden;
  }
  33%, to {
    visibility: visible;
  }
}
@-webkit-keyframes appear2 {
  from, 33% {
    visibility: hidden;
  }
  67%, to {
    visibility: visible;
  }
}
@keyframes appear2 {
  from, 33% {
    visibility: hidden;
  }
  67%, to {
    visibility: visible;
  }
}
@-webkit-keyframes appear3 {
  from, 67% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
@keyframes appear3 {
  from, 67% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
.navSticky {
  position: fixed;
  background-color: var(--clr-primary-300);
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
}

.navAbsolute {
  position: absolute;
  background-color: transparent;
}

.hidden {
  opacity: 0;
}

.reveal {
  -webkit-animation: reveal 1s backwards ease-out 2s;
          animation: reveal 1s backwards ease-out 2s;
}

.moveInRight {
  -webkit-animation: moveInRight 1.5s backwards ease-out 0.5s;
          animation: moveInRight 1.5s backwards ease-out 0.5s;
}

.moveInLeft {
  -webkit-animation: moveInLeft 1.5s backwards ease-out 0.5s;
          animation: moveInLeft 1.5s backwards ease-out 0.5s;
}

.moveInBottom {
  -webkit-animation: moveInBottom 1s backwards ease-out;
          animation: moveInBottom 1s backwards ease-out;
}

.moveInBottomIntro {
  -webkit-animation: moveInBottom 1s backwards ease-out 1s;
          animation: moveInBottom 1s backwards ease-out 1s;
}

.textClip {
  -webkit-animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
          animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}

.outerLeft {
  -webkit-animation: outer-left 1s 1s ease backwards;
          animation: outer-left 1s 1s ease backwards;
}

.buttonCta {
  -webkit-animation: buttonCta 2s backwards ease-out;
          animation: buttonCta 2s backwards ease-out;
}

.buttonGhost {
  -webkit-animation: buttonGhost 2s backwards ease-out;
          animation: buttonGhost 2s backwards ease-out;
}/*# sourceMappingURL=index.css.map */