.container {
  margin-bottom: 20rem;
  -webkit-animation: colorChange 2s forwards ease-out;
          animation: colorChange 2s forwards ease-out;
}

.textBoxOuter {
  -webkit-animation: outer-left 1s 1s ease backwards;
          animation: outer-left 1s 1s ease backwards;
}
@media only screen and (max-width: 37.5em) {
  .textBoxOuter {
    -webkit-animation: outer-up 1s 1s ease backwards;
            animation: outer-up 1s 1s ease backwards;
  }
}

.textBoxInner {
  -webkit-animation: inner-left 1s 1s ease backwards;
          animation: inner-left 1s 1s ease backwards;
}
@media only screen and (max-width: 37.5em) {
  .textBoxInner {
    -webkit-animation: inner-up 1s 1s ease backwards;
            animation: inner-up 1s 1s ease backwards;
  }
}

.textBoxInner1,
.textBoxInner2 {
  -webkit-animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;
          animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}
@media only screen and (max-width: 37.5em) {
  .textBoxInner1,
.textBoxInner2 {
    -webkit-animation: outer-up 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;
            animation: outer-up 1s 1s cubic-bezier(0.5, 0, 0.1, 1) backwards;
  }
}

.text1 {
  -webkit-animation: inner-left 1s 1s ease backwards, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
          animation: inner-left 1s 1s ease backwards, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}
@media only screen and (max-width: 37.5em) {
  .text1 {
    -webkit-animation: inner-up 1s 1s ease backwards, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
            animation: inner-up 1s 1s ease backwards, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
  }
}

.text2 {
  -webkit-animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
          animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) backwards;
}

.questionmark {
  display: inline-block;
  transition: all 1s;
  transform: rotate(95deg) translateX(1rem);
  -webkit-animation: questionmark 1.3s forwards ease-out 0.5s;
          animation: questionmark 1.3s forwards ease-out 0.5s;
}

@-webkit-keyframes questionmark {
  0% {
    transform: rotate(95deg) translateX(1rem);
  }
  50% {
    transform: rotate(85deg) translateX(1rem);
  }
  70% {
    transform: rotate(45deg) translateX(0.5rem);
  }
  90% {
    transform: rotate(-5deg) translateX(-0.5rem);
    color: var(--clr-shadow-500);
  }
  100% {
    transform: rotate(0) translateX(0);
    color: var(--clr-neutral-900);
  }
}

@keyframes questionmark {
  0% {
    transform: rotate(95deg) translateX(1rem);
  }
  50% {
    transform: rotate(85deg) translateX(1rem);
  }
  70% {
    transform: rotate(45deg) translateX(0.5rem);
  }
  90% {
    transform: rotate(-5deg) translateX(-0.5rem);
    color: var(--clr-shadow-500);
  }
  100% {
    transform: rotate(0) translateX(0);
    color: var(--clr-neutral-900);
  }
}
@-webkit-keyframes colorChange {
  0% {
    color: var(--clr-shadow-500);
  }
  80% {
    color: var(--clr-shadow-500);
  }
  100% {
    color: var(--clr-neutral-900);
  }
}
@keyframes colorChange {
  0% {
    color: var(--clr-shadow-500);
  }
  80% {
    color: var(--clr-shadow-500);
  }
  100% {
    color: var(--clr-neutral-900);
  }
}
@-webkit-keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@-webkit-keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}
@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes outer-up {
  from {
    transform: translateY(200%);
  }
  to {
    transform: none;
  }
}
@keyframes outer-up {
  from {
    transform: translateY(200%);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes inner-up {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: none;
  }
}
@keyframes inner-up {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: none;
  }
}/*# sourceMappingURL=Header.module.css.map */