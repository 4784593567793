@use './../styles/utilities/flow';
@use './../styles/abstracts/breakpoints';

//parent
.container {
  padding-block: flow.$padding-xl;
  overflow: hidden;
  width: 100%;

  padding-inline: flow.$padding-sm;

  @include breakpoints.respond(phone-reg) {
    padding-inline: flow.$padding-mn;
  }
}

//children-1
.introBox {
  width: min-content;
}

.introText {
  //800px
  @media only screen and (max-width: 50em) {
    font-size: 9.5rem;
  }

  @include breakpoints.respond(phone-reg) {
    font-size: 6.3rem;
  }

  @include breakpoints.respond(phone-mini) {
    font-size: 5.3rem;
  }
}
