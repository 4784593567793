@use './../../styles/abstracts/variables';
@use './../../styles/abstracts/breakpoints';
@use './../../styles/utilities/flow';

.container {
  //layout
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  //position
  position: relative;
  margin: 0;

  //sizing
  width: 60vw;
  height: 40vw;
  max-width: 380px;
  max-height: 250px;

  //styling
  perspective: 1000px;
  transform-origin: center;
}

// the element that rotates with the animation
.content {
  //position
  position: absolute;

  //sizing
  width: 100%;
  height: 100%;

  //styling
  transform-origin: center;
  transform-style: preserve-3d;
  transform: translateZ(-30vw) rotateY(0);
  animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards 1s;

  &.step-animation {
    animation: carousel 8s infinite steps(1) forwards;
  }
}

// Individual cards
.item {
  //position
  position: absolute;
  transform-origin: center;
  inset: 0;

  //sizing
  width: clamp(20rem, 50vw, 25rem);
  height: clamp(10rem, 40vw, 18rem);

  //alignment
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  //styling
  box-shadow: variables.$shadow-nuemorphic-bump-1;
  border-radius: 15px;

  &:nth-child(1) {
    transform: rotateY(0) translateZ(35vw);
  }

  &:nth-child(2) {
    transform: rotateY(120deg) translateZ(35vw);
  }

  &:nth-child(3) {
    transform: rotateY(240deg) translateZ(35vw);
  }
}

.img {
  width: 20rem;
  height: 10rem;

  @include breakpoints.respond(phone-reg) {
    width: 15rem;
    height: 7rem;
  }
}

//animation
@keyframes carousel {
  0%,
  17.5% {
    transform: translateZ(-35vw) rotateY(0);
  }
  27.5%,
  45% {
    transform: translateZ(-35vw) rotateY(-120deg);
  }
  55%,
  72.5% {
    transform: translateZ(-35vw) rotateY(-240deg);
  }
  82.5%,
  100% {
    transform: translateZ(-35vw) rotateY(-360deg);
  }
}
