@use './../../styles/abstracts/breakpoints';
@use './../../styles/utilities/flow';

//parent
.container {
  position: relative;
  padding-block: flow.$padding-xl;
  padding-inline: flow.$padding-sm;
  overflow: hidden;
  width: 100%;

  //layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15rem;

  @include breakpoints.respond(phone-reg) {
    padding-inline: flow.$padding-mn;
  }
}

//children-1
.heading {
  align-self: flex-start;
  padding-inline: flow.$padding-sm;

  @include breakpoints.respond(phone-reg) {
    padding-inline: flow.$padding-mn;
  }
}

//children-2
.brandImg {
  justify-self: center;
}

//children-3
.video {
  margin-block: flow.$margin-lg;
  margin-inline: auto;

  @include breakpoints.respond(phone-reg) {
    margin-top: flow.$margin-md;
    margin-bottom: 0;
  }
}

//children-4
.contentBox {
  //others
  width: 100%;
  padding-inline: flow.$padding-md;

  //layout
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  grid-template-areas:
    'head text1'
    '. text2'
    '. text3'
    'back btn';

  .contentHeading {
    grid-area: head;
    align-self: flex-start;
  }

  .contentParagraph1 {
    grid-area: text1;
  }

  .contentParagraph2 {
    grid-area: text2;
  }

  .contentParagraph3 {
    grid-area: text3;
  }

  .button {
    grid-area: btn;
    justify-self: end;
  }

  .buttonBack {
    grid-area: back;
    @include breakpoints.respond(tab-port) {
      justify-self: end;
    }
  }

  @include breakpoints.respond(tab-port) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'head'
      'text1'
      'text2'
      'text3'
      'btn'
      'back';
    gap: 5rem;
    padding-inline: flow.$padding-mn;
  }
}

.contentHeading {
  width: 30rem;
}

.contentParagraph {
  color: var(--clr-ash-500);
  margin-bottom: flow.$margin-lg;
  line-height: 2;

  @include breakpoints.respond(tab-port) {
    margin-bottom: flow.$margin-sm;
  }
}

.highlightText {
  color: var(--clr-neutral-900);
}

.button {
  margin-top: flow.$margin-md;
  @include breakpoints.respond(tab-port) {
    margin-top: flow.$margin-sm;
  }
}

.buttonBack {
  color: var(--clr-ash-500);
}
