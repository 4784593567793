.container {
  position: relative;
  height: 30rem;
}

.cardBox {
  perspective: 800px;
}

.card {
  background-color: var(--clr-primary-300);
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  transform-style: preserve-3d;
  transition: 1.2s;
  -webkit-animation: moveInBottom 1s backwards ease-out 1s;
          animation: moveInBottom 1s backwards ease-out 1s;
  width: clamp(30rem, 60vw, 65rem);
  height: clamp(28rem, 60vw, 40rem);
}

.side {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: rotate(0deg) translateZ(1px);
}

@media (hover: hover) {
  .cardBox:hover .card,
.back {
    transform: rotateY(-180deg) translateZ(1px);
  }
}
@media (hover: none) {
  .back {
    transform: rotateY(-180deg) translateZ(1px);
  }

  .card {
    -webkit-animation: moveInBottom 1s backwards ease-out 1s, flipCard 3s forwards ease-out 2s;
            animation: moveInBottom 1s backwards ease-out 1s, flipCard 3s forwards ease-out 2s;
  }
}
.front {
  line-height: 390px;
  text-align: center;
}
@media only screen and (max-width: 37.5em) {
  .front {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  outline: 1px solid var(--clr-neutral-100);
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: var(--ff-logo);
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 6px;
  display: inline-block;
  padding: 1.2rem 1.8rem;
}
@media only screen and (max-width: 37.5em) {
  .logo {
    padding: 0.8rem 1.2rem;
    font-size: 1.5rem;
  }
}

/* Back styling */
.back {
  background: var(--clr-primary-300);
  padding: 30px;
}
@media only screen and (max-width: 37.5em) {
  .back {
    padding: 20px;
  }
}

.name {
  font-weight: 500;
}

.job {
  color: var(--clr-ash-500);
}

.paragraph {
  margin-block: 1rem;
}

.info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: var(--clr-neutral-900);
}
@media only screen and (max-width: 37.5em) {
  .info {
    bottom: 10px;
    left: 20px;
  }
}

.property {
  color: var(--clr-shadow-300);
  margin-right: 1.5rem;
}

@-webkit-keyframes flipCard {
  0% {
    transform: rotate(0deg) translateZ(1px);
  }
  50% {
    transform: rotate(0deg) translateZ(1px);
  }
  100% {
    transform: rotateY(-180deg) translateZ(1px);
  }
}

@keyframes flipCard {
  0% {
    transform: rotate(0deg) translateZ(1px);
  }
  50% {
    transform: rotate(0deg) translateZ(1px);
  }
  100% {
    transform: rotateY(-180deg) translateZ(1px);
  }
}
@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}/*# sourceMappingURL=ContactCard.module.css.map */