@use './../../styles/abstracts/breakpoints';

.container {
  display: flex;
  width: 100%;

  @include breakpoints.respond(tab-port) {
    flex-direction: column;
    gap: 3rem;
  }
}

.number {
  font-weight: 200;
}

.title {
  width: 50%;
  margin-left: 5rem;
  justify-self: flex-end;

  @include breakpoints.respond(tab-land) {
    font-size: 10rem;
  }

  @include breakpoints.respond(tab-port) {
    justify-self: center;
    margin-left: 0;
    font-size: 7.5rem;
  }

  @include breakpoints.respond(phone-reg) {
    font-size: 4.9rem;
  }

  @include breakpoints.respond(phone-mini) {
    font-size: 4rem;
  }
}
