.container {
  display: block;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2rem;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  border-radius: 15px;
}
@media only screen and (max-width: 23.75em) {
  .container {
    padding: 1rem;
  }
}

.img {
  z-index: 2;
  width: clamp(28rem, 70vw, 50rem);
}/*# sourceMappingURL=Image.module.css.map */