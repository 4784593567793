@use './../../styles/abstracts/breakpoints';

.container {
  display: inline-block;
  width: max-content;
}

.small {
  display: inline-block;
  transform: rotate(-90deg) translateX(2rem);
  font-weight: 300;
  font-size: var(--fs-heading);

  @include breakpoints.respond(phone-reg) {
    transform: rotate(-90deg) translateX(1rem);
    font-size: 2.2rem;
  }

  @include breakpoints.respond(phone-mini) {
    font-size: 2rem;
  }
}

.large {
  @include breakpoints.respond(phone-mini) {
    font-size: 7.5rem;
  }
}
