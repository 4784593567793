.container {
  display: inline-block;
}

.text {
  font-weight: 700;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-family: var(--ff-logo);
  font-size: var(--fs-label);
  color: var(--clr-neutral-900) !important;
}

.text2 {
  color: var(--clr-ash-500) !important;
}/*# sourceMappingURL=Logo.module.css.map */