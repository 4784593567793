@use './../../styles/abstracts/variables';
@use './../../styles/abstracts/breakpoints';

.container {
  //layout
  display: block;
  display: flex;
  justify-content: center;

  //position
  position: relative;
  z-index: 2;

  //sizing
  width: min-content;
  padding: 2rem;

  //styling
  box-shadow: variables.$shadow-nuemorphic-bump-1;
  border-radius: 15px;

  @include breakpoints.respond(phone-mini) {
    padding: 1rem;
  }
}

.img {
  z-index: 2;
  width: clamp(28rem, 70vw, 50rem);
}
