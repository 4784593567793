*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-weight: 400;
  line-height: 1.6;
  color: var(--clr-neutral-900);
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  background-color: var(--clr-primary-300);
}

::selection {
  background-color: var(--clr-neutral-100);
}

.container-main {
  background-color: var(--clr-primary-300);
}
