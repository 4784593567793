.container {
  display: block;
  display: flex;
  justify-content: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2rem;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  border-radius: 15px;
}
@media only screen and (max-width: 23.75em) {
  .container {
    padding: 1rem;
  }
}

.video {
  width: clamp(28rem, 80vw, 80rem);
}
@media only screen and (max-width: 37.5em) {
  .video {
    width: clamp(28rem, 85vw, 80rem);
  }
}/*# sourceMappingURL=Video.module.css.map */