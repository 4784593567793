.container {
  position: absolute;
  top: -20rem;
  left: 30%;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  z-index: 10;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  border: none;
  border-radius: 50%;
  box-shadow: 6px 6px 12px var(--clr-primary-400), -6px -6px 12px var(--clr-primary-200);
  cursor: pointer;
  -webkit-animation: shadowFadeOut 3000ms ease-out forwards infinite, shadowFadeIn 3000ms 300ms ease-in forwards infinite;
          animation: shadowFadeOut 3000ms ease-out forwards infinite, shadowFadeIn 3000ms 300ms ease-in forwards infinite;
}
.circle::before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 20px 20px 60px var(--clr-primary-400), -20px -20px 60px var(--clr-primary-200);
  opacity: 0;
  -webkit-animation: fadeIn 400ms ease-out forwards infinite, outside_grow 5s ease-out infinite, fadeOut 3s 2s forwards infinite;
          animation: fadeIn 400ms ease-out forwards infinite, outside_grow 5s ease-out infinite, fadeOut 3s 2s forwards infinite;
}
.circle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  box-shadow: inset 20px 20px 60px var(--clr-primary-400), inset -20px -20px 60px var(--clr-primary-200);
  -webkit-animation: inside_grow 5s ease-out infinite;
          animation: inside_grow 5s ease-out infinite;
}

/** Animation */
@-webkit-keyframes outside_grow {
  from {
    width: 20px;
    height: 20px;
  }
  to {
    width: 900px;
    height: 900px;
  }
}
@keyframes outside_grow {
  from {
    width: 20px;
    height: 20px;
  }
  to {
    width: 900px;
    height: 900px;
  }
}
@-webkit-keyframes inside_grow {
  from {
    width: 0px;
    height: 0px;
  }
  to {
    width: 880px;
    height: 880px;
  }
}
@keyframes inside_grow {
  from {
    width: 0px;
    height: 0px;
  }
  to {
    width: 880px;
    height: 880px;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 6px 6px 12px var(--clr-primary-400), inset -6px -6px 12px var(--clr-primary-200);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 6px 6px 12px var(--clr-primary-400), -6px -6px 12px var(--clr-primary-200);
  }
}
@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 6px 6px 12px var(--clr-primary-400), inset -6px -6px 12px var(--clr-primary-200);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 6px 6px 12px var(--clr-primary-400), -6px -6px 12px var(--clr-primary-200);
  }
}
@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 6px 6px 12px var(--clr-primary-400), -6px -6px 12px var(--clr-primary-200);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: inset 6px 6px 12px var(--clr-primary-400), inset -6px -6px 12px var(--clr-primary-200);
  }
}
@keyframes shadowFadeOut {
  0% {
    box-shadow: 6px 6px 12px var(--clr-primary-400), -6px -6px 12px var(--clr-primary-200);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: inset 6px 6px 12px var(--clr-primary-400), inset -6px -6px 12px var(--clr-primary-200);
  }
}/*# sourceMappingURL=Ripple.module.css.map */