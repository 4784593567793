.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 8rem;
}

.img {
  width: auto;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
}
@media only screen and (max-width: 37.5em) {
  .img {
    width: clamp(30rem, 90vw, 50rem);
  }
}

.myself {
  transition: all 0.5s;
  -webkit-animation: filterSwitch 5s backwards linear infinite 2s;
          animation: filterSwitch 5s backwards linear infinite 2s;
}
.myself:hover {
  -webkit-animation: none;
          animation: none;
}

.contentBox {
  width: 100%;
  padding-inline: 5rem;
  margin-bottom: 20rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-template-areas: "head1 text1" ". text2" ". text3";
}
.contentBox .heading1 {
  grid-area: head1;
}
.contentBox .paragraph1 {
  grid-area: text1;
}
.contentBox .paragraph2 {
  grid-area: text2;
}
.contentBox .paragraph3 {
  grid-area: text3;
}
@media only screen and (max-width: 37.5em) {
  .contentBox {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "head1" "text1" "text2" "text3";
    gap: 5rem;
    padding-inline: 1rem;
  }
}

.heading {
  width: 30rem;
}

.paragraph {
  color: var(--clr-ash-500);
  margin-bottom: 8rem;
  line-height: 2;
}
@media only screen and (max-width: 56.25em) {
  .paragraph {
    margin-bottom: 1.5rem;
  }
}

.highlightText {
  color: var(--clr-neutral-900);
}

.highlightText {
  color: var(--clr-neutral-900);
}

@-webkit-keyframes filterSwitch {
  0% {
    filter: grayscale(0.9);
  }
  50% {
    filter: grayscale(0);
  }
  52% {
    filter: grayscale(0);
  }
  100% {
    filter: grayscale(0.9);
  }
}

@keyframes filterSwitch {
  0% {
    filter: grayscale(0.9);
  }
  50% {
    filter: grayscale(0);
  }
  52% {
    filter: grayscale(0);
  }
  100% {
    filter: grayscale(0.9);
  }
}/*# sourceMappingURL=AboutContent.module.css.map */