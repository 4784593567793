.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.contentBox {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.text1 {
  font-weight: 300;
  font-size: var(--fs-heading);
  display: block;
}

.text3,
.text4 {
  -webkit-animation: colorChange 3s backwards ease-out;
          animation: colorChange 3s backwards ease-out;
}

@media only screen and (max-width: 23.75em) {
  .text4 {
    font-size: 7.5rem;
  }
}

.button {
  margin-top: 1.5rem;
}

.floaterBox {
  position: absolute;
}

@-webkit-keyframes colorChange {
  0% {
    -webkit-text-stroke: 1px var(--clr-shadow-100);
    color: transparent;
  }
  30% {
    color: var(--clr-shadow-100);
  }
  100% {
    color: var(--clr-neutral-900);
  }
}

@keyframes colorChange {
  0% {
    -webkit-text-stroke: 1px var(--clr-shadow-100);
    color: transparent;
  }
  30% {
    color: var(--clr-shadow-100);
  }
  100% {
    color: var(--clr-neutral-900);
  }
}/*# sourceMappingURL=Home.module.css.map */