.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  position: relative;
  margin: 0;
  width: 60vw;
  height: 40vw;
  max-width: 380px;
  max-height: 250px;
  perspective: 1000px;
  transform-origin: center;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: translateZ(-30vw) rotateY(0);
  -webkit-animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards 1s;
          animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards 1s;
}
.content.step-animation {
  -webkit-animation: carousel 8s infinite steps(1) forwards;
          animation: carousel 8s infinite steps(1) forwards;
}

.item {
  position: absolute;
  transform-origin: center;
  inset: 0;
  width: clamp(20rem, 50vw, 25rem);
  height: clamp(10rem, 40vw, 18rem);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  border-radius: 15px;
}
.item:nth-child(1) {
  transform: rotateY(0) translateZ(35vw);
}
.item:nth-child(2) {
  transform: rotateY(120deg) translateZ(35vw);
}
.item:nth-child(3) {
  transform: rotateY(240deg) translateZ(35vw);
}

.img {
  width: 20rem;
  height: 10rem;
}
@media only screen and (max-width: 37.5em) {
  .img {
    width: 15rem;
    height: 7rem;
  }
}

@-webkit-keyframes carousel {
  0%, 17.5% {
    transform: translateZ(-35vw) rotateY(0);
  }
  27.5%, 45% {
    transform: translateZ(-35vw) rotateY(-120deg);
  }
  55%, 72.5% {
    transform: translateZ(-35vw) rotateY(-240deg);
  }
  82.5%, 100% {
    transform: translateZ(-35vw) rotateY(-360deg);
  }
}

@keyframes carousel {
  0%, 17.5% {
    transform: translateZ(-35vw) rotateY(0);
  }
  27.5%, 45% {
    transform: translateZ(-35vw) rotateY(-120deg);
  }
  55%, 72.5% {
    transform: translateZ(-35vw) rotateY(-240deg);
  }
  82.5%, 100% {
    transform: translateZ(-35vw) rotateY(-360deg);
  }
}/*# sourceMappingURL=CarouselLogo.module.css.map */