.container {
  transition: all 0.3s;
  -webkit-animation: moveAround backwards 20s linear infinite 4s;
          animation: moveAround backwards 20s linear infinite 4s;
  border-radius: 50%;
  box-shadow: 0.15em 0.15em 0.15em var(--clr-neutral-100) inset, -0.15em -0.15em 0.15em var(--clr-shadow-300) inset, 1em 1em 2em var(--clr-shadow-300), -1em -1em 2em var(--clr-neutral-100);
  width: 12rem;
  height: 12rem;
}
@media only screen and (max-width: 37.5em) {
  .container {
    width: 10rem;
    height: 10rem;
  }
}
@media only screen and (max-width: 23.75em) {
  .container {
    width: 8rem;
    height: 8rem;
  }
}

@-webkit-keyframes moveAround {
  0% {
    transform: translate(0, -50vh);
  }
  25% {
    transform: translate(-50vw, 0);
  }
  35% {
    transform: translate(-50vw, 0);
  }
  50% {
    transform: translate(0rem, 50vh);
  }
  60% {
    transform: translate(0rem, 50vh);
  }
  75% {
    transform: translate(50vw, 0);
  }
  85% {
    transform: translate(50vw, 0);
  }
  100% {
    transform: translate(0rem, -50vh);
  }
}

@keyframes moveAround {
  0% {
    transform: translate(0, -50vh);
  }
  25% {
    transform: translate(-50vw, 0);
  }
  35% {
    transform: translate(-50vw, 0);
  }
  50% {
    transform: translate(0rem, 50vh);
  }
  60% {
    transform: translate(0rem, 50vh);
  }
  75% {
    transform: translate(50vw, 0);
  }
  85% {
    transform: translate(50vw, 0);
  }
  100% {
    transform: translate(0rem, -50vh);
  }
}/*# sourceMappingURL=Ball.module.css.map */