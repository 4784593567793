@use './../../styles/abstracts/variables';
@use './../../styles/utilities/flow';
@use './../../styles/abstracts/breakpoints';

//children-2
.imgBox {
  //layout
  display: flex;
  justify-content: center;
  align-items: center;

  //others
  margin-block: flow.$margin-lg;
}

.img {
  width: auto;
  box-shadow: variables.$shadow-nuemorphic-bump-1;

  @include breakpoints.respond(phone-reg) {
    width: clamp(30rem, 90vw, 50rem);
  }
}

.myself {
  transition: all 0.5s;
  animation: filterSwitch 5s backwards linear infinite 2s;

  &:hover {
    animation: none;
  }
}

//children-3
.contentBox {
  //others
  width: 100%;
  padding-inline: flow.$padding-md;
  margin-bottom: flow.$margin-kg;

  //layout
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-template-areas:
    'head1 text1'
    '. text2'
    '. text3';

  .heading1 {
    grid-area: head1;
  }

  .paragraph1 {
    grid-area: text1;
  }

  .paragraph2 {
    grid-area: text2;
  }

  .paragraph3 {
    grid-area: text3;
  }

  @include breakpoints.respond(phone-reg) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'head1'
      'text1'
      'text2'
      'text3';
    gap: 5rem;
    padding-inline: flow.$padding-mn;
  }
}

.heading {
  width: 30rem;
}

.paragraph {
  color: var(--clr-ash-500);
  margin-bottom: flow.$margin-lg;
  line-height: 2;

  @include breakpoints.respond(tab-port) {
    margin-bottom: flow.$margin-sm;
  }
}

.highlightText {
  color: var(--clr-neutral-900);
}

.highlightText {
  color: var(--clr-neutral-900);
}

// Animation
@keyframes filterSwitch {
  0% {
    filter: grayscale(0.9);
  }

  50% {
    filter: grayscale(0);
  }

  52% {
    filter: grayscale(0);
  }

  100% {
    filter: grayscale(0.9);
  }
}
